.projects {
  margin-top: 2rem;
  margin-bottom: 2rem;

  h2 {
    text-align: center;
  }
}

.reveal .content {
  position: relative;
  border: 1px solid rgba(165, 92, 92, 0.05);

  .project-title {
    color: white;
    width: 90%;
    margin: 2rem auto;
    h4 {
      color: white;
    }
  }

  .project-link {
    position: absolute;
    bottom: 2rem;
    width: 100%;

    button {
      background-color: #46b231;
      color: white;
    }
  }

  &.hidden .overlay {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.projects-grid {
  text-align: center;
}

.ui.fade.active.reveal > .visible.content,
.ui.fade.reveal:hover > .visible.content {
  z-index: 0 !important;
}
