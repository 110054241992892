.hero {
  position: relative;
  height: 50vh;
  overflow: hidden;
  background-color: #46b231;
  background-image: url("../images/static-polygons.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .hero-active {
    // position: absolute;

    height: 50vh;
    background-image: url("../images/active-polygons.png");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .hero-overlay {
    width: 100vw;
    padding: 0 2rem;
    position: absolute;
    bottom: 10vh;
    text-align: center;
  }

  h1 {
    text-shadow: 2px 2px rgba(0, 0, 0, 0.6);
    font-size: 2.25rem !important;
  }

  .hero-overlay--description {
    color: white;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.6);
    font-size: 1.25rem !important;
    font-weight: bold;
  }
}

@media screen and (min-width: 529px) {
  .hero {
    .hero-overlay {
      bottom: 10vh;
    }

    h1 {
      font-size: 4.25rem !important;
    }

    .hero-overlay--description {
      font-size: 1.75rem !important;
    }
  }
}

@media screen and (min-width: 769px) {
  .hero {
    .hero-overlay {
      bottom: 20vh;
    }
  }
}
