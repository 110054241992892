.contact {
  background-color: #46b231;
  color: white;
  padding: 2rem 0;
  margin-top: 2rem;
  margin-bottom: 2rem;

  a,
  h2 {
    color: white !important;
  }
}

.copyright {
  text-align: right;
}
